"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-client-list"
  }, [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v("Prova de Vida")]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-align-content-flex-end"
  }, [_c("field-search-input", {
    staticClass: "mb-0",
    attrs: {
      placeholder: "Pesquise por CPF"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  })], 1), _vm._v(" "), _c("b-button", {
    attrs: {
      type: "is-primary"
    },
    on: {
      click: _vm.openModalNew
    }
  }, [_vm._v("\n      Gerar Nova Validação\n    ")])], 1), _vm._v(" "), _vm.listItems.length ? _c("div", {
    staticClass: "content-list-table"
  }, [_c("table-list-livenes-person", {
    attrs: {
      list: _vm.listClients,
      "action-open": _vm.openDetail
    }
  }), _vm._v(" "), _c("hr"), _vm._v(" "), _c("b-pagination", {
    attrs: {
      total: _vm.total,
      "per-page": _vm.limit,
      "aria-next-label": "Próxima página",
      "aria-previous-label": "Página anterior",
      "aria-page-label": "Página",
      "aria-current-label": "Página corrente"
    },
    model: {
      value: _vm.page,
      callback: function callback($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1) : _c("div", [_c("not-found")], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;