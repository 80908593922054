"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _vueGtag = _interopRequireDefault(require("vue-gtag"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
_vue.default.use(_vueRouter.default);
var router = new _vueRouter.default({
  linkExactActiveClass: 'active',
  routes: [{
    path: '/full-page',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/FullPage.vue'));
      });
    },
    children: [{
      meta: {
        title: 'Login'
      },
      path: '/login',
      name: 'login',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Login.vue'));
        });
      }
    }, {
      meta: {
        title: 'Recuperação de Senha'
      },
      path: '/recovery-pass',
      name: 'RecoveryPass',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/RecoveryPass.vue'));
        });
      }
    }, {
      meta: {
        title: 'Resetar a Senha'
      },
      path: '/reset-pass',
      name: 'ResetPass',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ResetPass.vue'));
        });
      }
    }, {
      meta: {
        title: 'Resetar a Senha'
      },
      path: '/reset-pass-app',
      name: 'ResetPassAPP',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ResetPassAPP.vue'));
        });
      }
    }]
  }, {
    path: '/',
    redirect: '/login'
  }, {
    path: '/dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    meta: {
      title: 'ÍNDICES'
    },
    children: [{
      path: '/dashboard-list',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Dashboard/index.vue'));
        });
      },
      name: 'dashboard',
      meta: {
        title: 'Dashboard',
        clients: []
      }
    }, {
      path: '/detail-dashboard-item-list/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/DetailDashboardItem.vue'));
        });
      },
      name: 'DetailDashboardItem',
      meta: {
        title: 'Dashboard',
        clients: []
      }
    }, {
      path: '/dashboard-list-errors/:token',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/DashboardListErrors.vue'));
        });
      },
      name: 'DashboardListErrors',
      meta: {
        title: 'Dashboard',
        clients: []
      }
    }, {
      path: '/dashboard-details-service',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/DashboardDetails/DashboardDetailsService.vue'));
        });
      },
      name: 'DashboardDetailsService',
      meta: {
        title: 'Dashboard Details',
        clients: []
      }
    }]
  }, {
    path: '/clients',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/clients-list',
      name: 'ClientsList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ClientList.vue'));
        });
      },
      meta: {
        title: 'Clientes',
        clients: []
      }
    }, {
      path: '/clients-form',
      name: 'ClientForm',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ClientForm/index.vue'));
        });
      },
      meta: {
        title: 'Clientes',
        clients: []
      }
    }, {
      path: '/clients-form/:id',
      name: 'ClientFormEdit',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ClientForm/index.vue'));
        });
      },
      meta: {
        title: 'Clientes',
        clients: []
      }
    }, {
      path: '/clients-detail/:id',
      name: 'ClientDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ClientDetail.vue'));
        });
      },
      meta: {
        title: 'Cliente',
        clients: []
      }
    }, {
      path: '/business-unit-form/:id',
      name: 'BusinessUnitForm',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/BusinessUnitForm/index.vue'));
        });
      },
      meta: {
        title: 'Area de negocio',
        clients: []
      }
    }]
  }, {
    path: '/users',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/users-list',
      name: 'UsersList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/UsersList.vue'));
        });
      },
      meta: {
        title: 'Usuário',
        clients: []
      }
    }, {
      path: '/users-form',
      name: 'UsersForm',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/UsersForm.vue'));
        });
      },
      meta: {
        title: 'Usuário',
        clients: []
      }
    }, {
      path: '/users-form/:id',
      name: 'UsersFormEdit',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/UsersForm.vue'));
        });
      },
      meta: {
        title: 'Usuário',
        clients: []
      }
    }, {
      path: '/users-detail/:id',
      name: 'UserDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/UserDetail/index.vue'));
        });
      },
      meta: {
        title: 'Usuário',
        clients: []
      }
    }]
  }, {
    path: '/connection-monitor-pages',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/connection-monitor',
      name: 'ConnectionMonitor',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ConnectionMonitor.vue'));
        });
      },
      meta: {
        title: 'Monitor de Conexões',
        clients: []
      }
    }, {
      path: '/connection-service-detail/:id',
      name: 'ConnectionServiceDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ConnectionServiceDetail.vue'));
        });
      },
      meta: {
        title: 'Detalhes do serviço',
        clients: [],
        subpage: true
      }
    }]
  }, {
    path: '/products-services',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/products-services-list',
      name: 'ServiceActivesList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ServiceActivesList.vue'));
        });
      },
      meta: {
        title: 'Produtos / Serviços',
        clients: []
      }
    }, {
      path: '/service-detail-extract/:id',
      name: 'ServiceDetailExtract',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ServiceDetailExtract.vue'));
        });
      },
      meta: {
        title: 'Detalhes do Serviço',
        clients: []
      }
    }]
  }, {
    path: '/consumers',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/consumers-list',
      name: 'ConsumersList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ConsumersList.vue'));
        });
      },
      meta: {
        title: 'Consumidores',
        clients: []
      }
    }, {
      path: '/consumers-detail/:id',
      name: 'ConsumersDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ConsumersProvidersDetail.vue'));
        });
      },
      meta: {
        title: 'Consumidor',
        clients: []
      }
    }]
  }, {
    path: '/providers',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/providers-list',
      name: 'ProvidersList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ProvidersList.vue'));
        });
      },
      meta: {
        title: 'Fornecedores',
        clients: []
      }
    }, {
      path: '/providers-detail/:id',
      name: 'ProvidersDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ConsumersProvidersDetail.vue'));
        });
      },
      meta: {
        title: 'Fornecedor',
        clients: []
      }
    }]
  }, {
    path: '/end-users',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/end-users-list',
      name: 'EndUsersList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/EndUsersList.vue'));
        });
      },
      meta: {
        title: 'Usuários Finais',
        clients: []
      }
    }]
  }, {
    path: '/new-services',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/services-list',
      name: 'ServicesList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ServicesList.vue'));
        });
      },
      meta: {
        title: 'Novos serviços',
        clients: []
      }
    }, {
      path: '/service-detail/:id',
      name: 'ServiceDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ServiceDetail.vue'));
        });
      },
      meta: {
        title: 'Detalhes do serviço',
        clients: []
      }
    }]
  }, {
    path: '/new-apis',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/new-service',
      name: 'NewServiceForm',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/NewServiceForm/index.vue'));
        });
      },
      meta: {
        title: 'Novos API',
        clients: []
      }
    }, {
      path: '/update-service/:id',
      name: 'UpdateServiceForm',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/NewServiceForm/index.vue'));
        });
      },
      meta: {
        title: 'Novos API',
        clients: []
      }
    }]
  }, {
    path: '/autorizeds',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/autorized-consumers',
      name: 'AutorizedConsumers',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/AutorizedConsumers.vue'));
        });
      },
      meta: {
        title: 'Autorização de serviços',
        clients: []
      }
    }]
  }, {
    path: '/profiles',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/profile/user',
      name: 'ProfileUser',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ProfileUser.vue'));
        });
      },
      meta: {
        title: 'Perfil de usuário',
        clients: []
      }
    }]
  }, {
    path: '/services',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/services/test/sandbox/:id',
      name: 'ServiceTextSandbox',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ServiceTextSandbox.vue'));
        });
      },
      meta: {
        title: 'Teste em Sandbox',
        clients: []
      }
    }, {
      path: '/services/documentation/:id',
      name: 'ServicePortalDocumentation',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ServicePortalDocumentation.vue'));
        });
      },
      meta: {
        title: 'Teste em Sandbox',
        clients: []
      }
    }, {
      path: '/services/batch-processing/:id',
      name: 'BatchProcessingList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/BatchProcessingList.vue'));
        });
      },
      meta: {
        title: 'Processamento em lote',
        clients: []
      }
    }, {
      path: '/detail-service/:id',
      name: 'ServiceDetailConnectionMonitor',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ServiceDetailConnectionMonitor/index.vue'));
        });
      },
      meta: {
        title: 'Detalhe do Serviço',
        clients: [],
        subpage: true
      }
    }]
  }, {
    path: '/extract',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/extract/finances/:id',
      name: 'ExtractFinances',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ExtractFinances.vue'));
        });
      },
      meta: {
        title: 'Extrato de fornecedor',
        clients: []
      }
    }, {
      path: '/extract/consumer/:id',
      name: 'ExtractFinancesConsumer',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ExtractFinancesConsumer.vue'));
        });
      },
      meta: {
        title: 'Extrato de consumidor',
        clients: []
      }
    }, {
      path: '/extract/requests/:id',
      name: 'ExtractRequests',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ExtractRequests.vue'));
        });
      },
      meta: {
        title: 'Extrato de requisições',
        clients: []
      }
    }, {
      path: '/extract/requests-error/:id',
      name: 'ExtractRequestsError',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ExtractRequests.vue'));
        });
      },
      meta: {
        title: 'Extrato de requisições de erro',
        clients: []
      }
    }, {
      path: '/extract/requests/consumer/:id',
      name: 'ExtractRequestsByConsumer',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ExtractRequestsbyConsumer.vue'));
        });
      },
      meta: {
        title: 'Extrato de requisições',
        clients: []
      }
    }, {
      path: '/extract/requests-error/consumer/:id',
      name: 'ExtractRequestsErrorByConsumer',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ExtractRequestsbyConsumer.vue'));
        });
      },
      meta: {
        title: 'Extrato de requisições',
        clients: []
      }
    }, {
      path: '/extract/results/:id',
      name: 'ExtractResults',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ExtractResults.vue'));
        });
      },
      meta: {
        title: 'Extrato de requisições de erro',
        clients: []
      }
    }, {
      path: '/extract/documents/financial/:id',
      name: 'ExtractFinancialDocuments',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ExtractFinancialDocuments.vue'));
        });
      },
      meta: {
        title: 'Extrato Financeiro',
        clients: []
      }
    }]
  }, {
    path: '/services-types',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/services-types-list',
      name: 'ServiceTypesList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ServiceTypes.vue'));
        });
      },
      meta: {
        title: 'Tipos de serviço',
        clients: []
      }
    }, {
      path: '/service-type/:id',
      name: 'ServiceTypesDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ServiceTypesDetail.vue'));
        });
      },
      meta: {
        title: 'Detalhe do Tipo de serviço',
        clients: []
      }
    }]
  }, {
    path: '/documentation',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/documentation-services',
      name: 'DocumentationServices',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/DocumentationServices.vue'));
        });
      },
      meta: {
        title: 'Documentação de serviços',
        clients: []
      }
    }, {
      path: '/documentation/edit/:id',
      name: 'DocumentationServiceEdit',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/DocumentationServiceEdit.vue'));
        });
      },
      meta: {
        title: 'Editar documentação',
        clients: []
      }
    }]
  }, {
    path: '/people-analysis',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/personal-consultation-list',
      name: 'PersonalConsultation',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/PersonalConsultation/index.vue'));
        });
      },
      meta: {
        title: 'Consulta Pessoal',
        clients: ['DICRE DFE', 'Consulta Análise Pessoal', 'Brasilprev']
      }
    }, {
      path: '/personal-consultation/:id',
      name: 'PersonalConsultationDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/PersonalConsultationDetail/index.vue'));
        });
      },
      meta: {
        title: 'Detalhes de Consulta Pessoal',
        clients: ['DICRE DFE', 'Consulta Análise Pessoal', 'Brasilprev']
      }
    }]
  }, {
    path: '/bond-strength',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/bond-strength-detail/:id',
      name: 'BondStrengthDetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/PersonalConsultation/components/BondStrength/BondStrengthDetail.vue'));
        });
      },
      meta: {
        title: 'Força de Vínculo',
        clients: ['DICRE DFE', 'Consulta Análise Pessoal', 'Brasilprev']
      }
    }]
  }, {
    path: '/suspicious-protocols',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/suspicious-protocols/list',
      name: 'SuspiciousProtocolsList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/SuspiciousProtocolsList.vue'));
        });
      },
      meta: {
        title: 'Listagem de Protocolos Suspeitos',
        clients: []
      }
    }, {
      path: '/suspicious-protocols/detail/:id',
      name: 'SuspiciousProtocolsDetails',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/SuspiciousProtocolsDetails.vue'));
        });
      },
      meta: {
        title: 'Listagem de Protocolos Suspeitos',
        clients: []
      }
    }]
  }, {
    path: '/share-info',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/share-info-detail',
      name: 'ShareInfo',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ShareInfo.vue'));
        });
      },
      meta: {
        title: 'Compartilhamento',
        clients: []
      }
    }]
  }, {
    path: '/gravame-contract',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/gravame-contract/consult',
      name: 'Consult',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Gravame.vue'));
        });
      },
      meta: {
        title: 'Gravame',
        clients: []
      }
    }, {
      path: '/gravame-contract/register',
      name: 'Register',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Gravame/Register.vue'));
        });
      },
      meta: {
        title: 'Cadastrar',
        clients: []
      }
    }, {
      path: '/gravame-contract/alter',
      name: 'Alter',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Gravame/Alter.vue'));
        });
      },
      meta: {
        title: 'Alterar',
        clients: []
      }
    }, {
      path: '/gravame-contract/add',
      name: 'Add',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Gravame/Add.vue'));
        });
      },
      meta: {
        title: 'Aditivar',
        clients: []
      }
    }, {
      path: '/gravame-contract/redistribute',
      name: 'Redistribute',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Gravame/Redistribute.vue'));
        });
      },
      meta: {
        title: 'Redistribuir em Lote',
        clients: []
      }
    }]
  }, {
    path: '/monitoring-report-dash',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/monitoring-report',
      name: 'MonitoringReport',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/MonitoringReport/index.vue'));
        });
      },
      meta: {
        title: 'Monitoramento de seriços',
        clients: []
      }
    }, {
      path: '/data-tracking',
      name: 'DataTracking',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/DataTracking/index.vue'));
        });
      },
      meta: {
        title: 'Acompanhamento',
        clients: []
      }
    }]
  }, {
    path: '/invite-facematch',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/invite-facematch-form',
      name: 'InviteFacematchForm',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/InviteFacematch/index.vue'));
        });
      },
      meta: {
        title: 'Convite Facematch',
        clients: []
      }
    }, {
      path: '/invite-facematch-form-hive',
      name: 'InviteFacematchFormHive',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/InviteFacematchHive/index.vue'));
        });
      },
      meta: {
        title: 'Convite Facematch',
        clients: []
      }
    }]
  }, {
    path: '/open-finance',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/open-finance/dashboard',
      name: 'OpenFinanceDashboard',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/OpenFinanceDashboard.vue'));
        });
      },
      meta: {
        title: 'Dashboard OpenFinance',
        clients: []
      }
    }]
  }, {
    path: '/onboarding',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/onboarding/list-business-unit',
      name: 'OnboardingListBusinesUnit',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/OnboardingListBusinesUnit.vue'));
        });
      },
      meta: {
        title: 'OnboardingListBusinesUnit',
        clients: ['CBTU', 'Banestes']
      }
    }, {
      path: '/onboarding/:id/list',
      name: 'OnboardingListUserByBusinessUnit',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/OnboardingListUserByBusinessUnit.vue'));
        });
      },
      meta: {
        title: 'OnboardingListUserByBusinessUnit',
        clients: ['CBTU', 'Banestes']
      }
    }, {
      path: '/onboarding/:id/detail',
      name: 'OnboardingDetailPerson',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/OnboardingDetailPerson/index.vue'));
        });
      },
      meta: {
        title: 'OnboardingDetailPerson',
        clients: ['CBTU', 'Banestes']
      }
    }]
  }, {
    path: '/payroll',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/payroll/loans',
      name: 'PayrollLoans',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/PayrollLoans.vue'));
        });
      },
      meta: {
        title: 'PayrollLoans',
        clients: []
      }
    }]
  }, {
    path: '/eqxbv',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/eqxbv',
      name: 'EQXBV',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Eqxbv.vue'));
        });
      },
      meta: {
        title: 'Boa Vista',
        clients: ['DICRE DFE', 'USD - BB']
      }
    }]
  }, {
    path: '/riskdevice',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/riskdevice',
      name: 'RiskDevice',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/RiskDevice.vue'));
        });
      },
      meta: {
        title: 'RiskDevice',
        clients: ['DICRE DFE', 'USD - BB']
      }
    }]
  }, {
    path: '/support',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      name: 'Support',
      path: '/support',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Tickets/index.vue'));
        });
      },
      meta: {
        title: 'Support',
        clients: []
      }
    }, {
      name: 'Ticket',
      path: '/support/ticket/:ticketId',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Tickets/detail.vue'));
        });
      },
      meta: {
        title: 'Ticket',
        clients: []
      }
    }, {
      name: 'New',
      path: '/support/novo-ticket',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Tickets/open-new.vue'));
        });
      },
      meta: {
        title: 'New',
        clients: []
      }
    }, {
      name: 'ListTickets',
      path: '/support/managements/list',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Tickets/ListManagements.vue'));
        });
      },
      meta: {
        title: 'List',
        clients: []
      }
    }]
  }, {
    path: '/liveness-hive',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      name: 'Prova de Vida',
      path: '/liveness-hive/list',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/LivenessHive/index.vue'));
        });
      },
      meta: {
        title: 'Prova de Vida',
        clients: []
      }
    }, {
      name: 'Detalhes do Prova de Vida',
      path: '/liveness-hive/detail/:idItem',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/LivenessHiveDetail/index.vue'));
        });
      },
      meta: {
        title: 'Detalhes do Prova de Vida',
        clients: []
      }
    }]
  }, {
    path: '/batch-process',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      path: '/batch-process/all-batch-process',
      name: 'ListAllBatchProcessing',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/ListAllBatchProcessing.vue'));
        });
      },
      meta: {
        title: 'Processamento em Lote',
        clients: []
      }
    }]
  }, {
    path: '/audit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      name: 'Auditoria',
      path: '/audit/clients',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Audit/index.vue'));
        });
      },
      meta: {
        title: 'Auditoria Clientes',
        clients: []
      }
    }, {
      name: 'Auditoria de Arquivos',
      path: '/audit/logfiles/:clientId',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Audit/AuditLogfiles.vue'));
        });
      },
      meta: {
        title: 'Auditoria de arquivos',
        clients: []
      }
    }]
  }, {
    path: '/access-control',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/index.vue'));
      });
    },
    children: [{
      name: 'Controle de Acesso',
      path: '/access-control/modules',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/AccessControl/index.vue'));
        });
      },
      meta: {
        title: 'Controle de Acesso',
        clients: []
      }
    }, {
      name: 'Páginas Portal',
      path: '/access-control/pages',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Pages/PagesList.vue'));
        });
      },
      meta: {
        title: 'Listagem de Páginas',
        clients: []
      }
    }, {
      name: 'Cadastrar Página',
      path: '/access-control/pages-form',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Pages/PagesForm.vue'));
        });
      },
      meta: {
        title: 'Cadastrar Página',
        clients: []
      }
    }, {
      name: 'Alterar Página',
      path: '/access-control/pages-form/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Pages/PagesForm.vue'));
        });
      },
      meta: {
        title: 'Alterar Página',
        clients: []
      }
    }, {
      name: 'Grupos de Acesso',
      path: '/access-control/access-group',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/AccessControl/AccessGroupList.vue'));
        });
      },
      meta: {
        title: 'Grupos de Acesso',
        clients: []
      }
    }, {
      name: 'Cadastrar Grupo de Acesso',
      path: '/access-control/access-group-form',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/AccessControl/AccessGroupForm.vue'));
        });
      },
      meta: {
        title: 'Grupos de Acesso',
        clients: []
      }
    }, {
      name: 'Cadastrar Grupo de Acesso',
      path: '/access-control/access-group-form/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/AccessControl/AccessGroupForm.vue'));
        });
      },
      meta: {
        title: 'Grupos de Acesso',
        clients: []
      }
    }]
  }, {
    path: '/painel',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/layout/NoSidebar.vue'));
      });
    },
    children: [{
      name: 'Painel',
      path: '/painel/watch',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/Painel/index.vue'));
        });
      },
      meta: {
        title: 'Painel de Observação',
        clients: []
      }
    }]
  }],
  scrollBehavior: function scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
});
_vue.default.use(_vueGtag.default, {
  config: {
    id: process.env.VUE_APP_API_KEY_GA
  },
  pageTrackerTemplate: function pageTrackerTemplate(to) {
    var _to$meta;
    return {
      page_title: to === null || to === void 0 ? void 0 : (_to$meta = to.meta) === null || _to$meta === void 0 ? void 0 : _to$meta.title,
      page_path: to.path
    };
  }
}, router);
var _default = exports.default = router;