"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$data, _vm$data2;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cols-form tab-card-validation-face-component"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [!_vm.validationFace ? _c("not-found") : _vm._e(), _vm._v(" "), _vm.validationFace ? _c("card", {
    staticClass: "card-detail-image"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column col-img"
  }, [_vm.validationFace ? _c("img", {
    staticClass: "img-raised",
    attrs: {
      src: _vm.validationFace
    },
    on: {
      click: function click($event) {
        return _vm.openModal(_vm.validationFace);
      }
    }
  }) : _c("img", {
    staticClass: "img-raised",
    attrs: {
      src: require("@/assets/images/avatar-default.png")
    }
  })]), _vm._v(" "), _c("div", {
    staticClass: "column col-details-text"
  }, [_c("h2", [_vm._v("SCORE SPOOFING: 1")]), _vm._v(" "), _c("h2", [_vm._v("SCORE FACEMATCH: " + _vm._s(((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.validationFacematch) || "--"))]), _vm._v(" "), _c("h4", [_vm._v("Data: " + _vm._s(_vm._f("dateComplete")((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : _vm$data2.dateInit)))])])])]) : _vm._e()], 1)]), _vm._v(" "), _c("b-modal", {
    model: {
      value: _vm.isImageModalActive,
      callback: function callback($$v) {
        _vm.isImageModalActive = $$v;
      },
      expression: "isImageModalActive"
    }
  }, [_c("b-image", {
    attrs: {
      src: _vm.imgSelected,
      "webp-fallback": ".jpg"
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;