"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-table", {
    staticClass: "table-list-modern",
    attrs: {
      data: !_vm.list.length ? [] : _vm.list,
      "mobile-cards": true
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function fn() {
        return [_c("div", {
          staticClass: "has-text-centered"
        }, [_vm._v("Não há registros")])];
      },
      proxy: true
    }])
  }, [_c("b-table-column", {
    attrs: {
      field: "createdAt",
      label: "Acessos recentes"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        var _props$row;
        return [_vm._v("\n    " + _vm._s(_vm._f("dateComplete")((_props$row = props.row) === null || _props$row === void 0 ? void 0 : _props$row.updatedAt)) + "\n  ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "createdAt",
      label: "IP"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        var _props$row$ip, _props$row2;
        return [_vm._v("\n    " + _vm._s((_props$row$ip = (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.ip) !== null && _props$row$ip !== void 0 ? _props$row$ip : "-") + "\n  ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "createdAt",
      label: "Plataforma"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        var _props$row3;
        return [_vm._v("\n    " + _vm._s((_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.platform) + "\n  ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "createdAt",
      label: "Detalhes"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        var _props$row4;
        return [_vm._v("\n    " + _vm._s((_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.agent) + "\n  ")];
      }
    }])
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;